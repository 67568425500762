import React from 'react'

const Hari = () => {
    return (
        <svg className="i-img" width={623} height="100%" viewBox="0 0 623 817" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88 404L68.5 302L81 331.5L68.5 210L81 251L88 149.5L95.5 177.5L129 90.5V124.5L175 52.5V73.5L222.5 21L209 52.5L294 2L269.5 34.5L359 2L325 34.5L425.5 16.5L394.5 44.5L496 52.5L434.5 65L543 90.5H466.5L567 133L496 124.5L581 182L519 162L607 237L533.5 202L607 275.5L543 242.5L620.5 331.5L552.5 295L607 384.5L552.5 348.5L526 384.5" stroke="black" strokeWidth={4} className="svg-hari-1" />
            <path d="M1 814.999C7 816.333 40 806.8 124 758M124 758C127.333 740.666 132 702 173 690M124 758C124.667 772.666 134.2 804.599 167 814.999M173 690V704M173 690V590M173 704C163.103 706.942 152.734 721.518 173 756.926M173 704V756.926M611 815C605 816.333 572 806.8 488 758M488 758C484.667 740.667 480 702 439 690M488 758C487.333 772.667 477.8 804.6 445 815M439 690V704M439 690V606.919M439 704C448.897 706.942 459.266 721.519 439 756.927M439 704V756.927M173 590C162.333 573.333 158 573.5 145.5 535.5C136 535 116.4 528.6 114 507C111 491.167 102.7 455.8 93.5 441C89.1667 432.5 82.7 411.2 91.5 394C100.3 376.8 111.5 391.833 116 401.5L127.5 424.5M173 590C180 602 229.9 632.1 273.5 656.5L318 658L359 656.5C388.064 640.235 426.318 621.2 439 606.919M509 401.263C513.5 391.596 524.7 376.563 533.5 393.763C542.3 410.963 535.833 432.263 531.5 440.763C522.3 455.563 514 490.929 511 506.763C508.6 528.363 489 534.763 479.5 535.263C467 573.263 462.667 573.096 452 589.763C449.666 593.763 445.343 599.776 439 606.919M173 756.926C177.989 765.643 184.834 775.621 194 786.999L306 814.999L418 787C427.166 775.622 434.011 765.643 439 756.927" stroke="black" strokeWidth={4} className="svg-hari-2" />
            <path d="M211.5 364.5C205.9 356.5 208.167 347.5 210 344C216.833 341.667 233.5 338.3 245.5 343.5C247.9 348.3 247.833 354.5 247.5 357C245.9 368.2 234.833 372.333 229.5 373C225.833 373.5 217.1 372.5 211.5 364.5Z" stroke="black" strokeWidth={4} className="svg-hari-3" />
            <path d="M429.5 347.236C431.333 350.736 433.6 359.736 428 367.736C422.4 375.736 413.667 376.736 410 376.236C404.667 375.569 393.6 371.436 392 360.236C391.667 357.736 391.6 351.536 394 346.736" stroke="black" strokeWidth={4} className="svg-hari-4" />
            <path d="M178.5 366.5C193.333 348 233.9 321.3 277.5 362.5C233.1 384.1 193 374.167 178.5 366.5Z" stroke="black" strokeWidth={4} className="svg-hari-5" />
            <path d="M461 369.736C446.167 351.236 405.6 324.536 362 365.736C406.4 387.336 446.5 377.403 461 369.736Z" stroke="black" strokeWidth={4} className="svg-hari-6" />
            <path d="M286.115 580.841C308.705 581.215 363.25 573.082 400.711 537.552" stroke="black" strokeWidth={4} className="svg-hari-7" />
            <path d="M300 479.5C290.667 479.667 286.5 477 286.5 461C286.5 440.912 302.833 402.833 313 386" stroke="black" strokeWidth={4} className="svg-hari-8" />
            <path d="M346 479.5C355.333 479.667 359.5 477 359.5 461C359.5 440.912 343.167 402.833 333 386" stroke="black" strokeWidth={4} className="svg-hari-9" />
            <path d="M146 534.5C146.167 526.167 145 504.9 139 486.5C131.5 463.5 122 439.5 130.5 383.5L145.568 330.518C145.855 329.509 146.036 328.472 146.106 327.426L150.816 257.721C150.938 255.922 151.368 254.162 152.163 252.543C163.416 229.625 194.177 189.142 237 193C280.993 196.963 408.139 253.286 467.859 281.718C468.976 282.249 469.991 282.888 470.897 283.731C485.779 297.571 510.55 343.136 509 400.5C505.833 427.5 495.9 493.6 479.5 536" stroke="black" strokeWidth={4} className="svg-hari-10" />
            <path d="M277.5 309.252L271.5 333.752C265 325.585 234.6 309.252 165 309.252C222.2 285.252 263.833 299.252 277.5 309.252Z" stroke="black" strokeWidth={4} className="svg-hari-11" />
            <path d="M360 309.252L366 333.752C372.5 325.585 402.9 309.252 472.5 309.252C415.3 285.252 373.667 299.252 360 309.252Z" stroke="black" strokeWidth={4} className="svg-hari-12" />
        </svg>
    )
}

export default Hari