import React from 'react'
import "./work.css"
import Green from './Green'
import Link from './Link'
import Bot from './Bot'
import Lms from './Lms'
import Fid from './Fid'

const Mwork = () => {
    return (
        <>
            <div className='w'>
                <div className="w-left">
                    <div className="w-contain">
                        <h1 className="w-title">Cliqz URL Shortener</h1>
                        <p className="w-desc">
                            Cliqz is a URL shortening service, which is my first project which I deployed online with the help of free hosting and free domain😁. It uses default linux file system as its database as I was studying first year and had no knowledge about Database. This app is monetized by providing affiliate links in the landing page of the shortened URL.
                        </p>
                    </div>
                </div>
                <div className="w-right">
                    <div className="w-card">
                        <Link />
                    </div>
                </div>
            </div>
            <div className='w'>
                <div className="w-left">
                    <div className="w-contain">
                        <h1 className="w-title">Happy Bot</h1>
                        <p className="w-desc">
                            This is my first project on Machine Learning, I used Natural Language processing to develop a chatbot for my college, in which students and parents can interact with the bot to clarify their doubts about the college. This uses predefined(slightly changed according to my needs) dataset for training the bot.
                        </p>
                    </div>
                </div>
                <div className="w-right">
                    <div className="w-card">
                        <Bot />
                    </div>
                </div>
            </div>
            <div className='w'>
                <div className="w-left">
                    <div className="w-contain">
                        <h1 className="w-title">I Excel Tech</h1>
                        <p className="w-desc">
                            This was a group project where I worked as a backend developer, it was a training cum internship in which we were asked to develop a Learning Management System with the integration of Zoom meetings, Google meet, Google Calendar and OTP verification and authentication. Finally we deployed our website in Hostinger.
                        </p>
                    </div>
                </div>
                <div className="w-right">
                    <div className="w-card">
                        <Lms />
                    </div>
                </div>
            </div>
            <div className='w'>
                <div className="w-left">
                    <div className="w-contain">
                        <h1 className="w-title">Find Interior Design</h1>
                        <p className="w-desc">
                            This is my first internship project, which I got connected to the client through Internshala(online jobs and internship website in India). Two of us, my friend and I worked in this project where I worked at the backend of the application. In this project, we were asked to develop a web app to connect Interior Designers, Architects, Product vendors, etc... with the customers. I also worked in the user authentication for different users and payment gateway integration.
                        </p>
                    </div>
                </div>
                <div className="w-right">
                    <div className="w-card">
                        <Fid />
                    </div>
                </div>
            </div>
            <div className='w'>
                <div className="w-left">
                    <div className="w-contain">
                        <h1 className="w-title">Green Calibre</h1>
                        <p className="w-desc">
                            This is a E - Commerce website which fecilitates farmers and customers to sell and buy agriculture products without middle men. Since we eliminated the middle men, the farmers are more benefitted and the customers get products at low price in the market. This system includes three modules, i.e. customer, farmer and transporter. It is based on LAMP stack.
                        </p>
                    </div>
                </div>
                <div className="w-right">
                    <div className="w-card">
                        <Green />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mwork