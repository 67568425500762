import React from 'react'
import "./about.css"
import Aill from "./Aill"

const About = () => {
    return (
        <div className="a">
            <div className="a-left">
                {/* <div className="a-card"></div> */}
                <div className="a-card">
                    {/* <img src={Aill} alt="" className="a-img" /> */}
                    <Aill/>
                </div>
            </div>
            <div className="a-right">
                <h1 className="a-title">About Me</h1>
                <p className="a-desc">
                    A Website enthusiast, specializing in back end development based on PHP, NodeJS, MySQL, Google Datastore(NoSQL), experienced in deploying web servers online (both LAMP and MERN stack) and a mail server with the basic knowledge in Google cloud and Docker.
                </p>
                <table>
                    <tbody>
                        <tr className="a-item">
                            <td className="a-item-head">Name :</td>
                            <td className="a-item-item"> Hari Prasad</td>
                        </tr>
                        <tr className="a-item">
                            <td className="a-item-head">Date of Birth :</td>
                            <td className="a-item-item"> November 20, 2000</td>
                        </tr>
                        <tr className="a-item">
                            <td className="a-item-head">Country :</td>
                            <td className="a-item-item"> India</td>
                        </tr>
                        <tr className="a-item">
                            <td className="a-item-head">Email :</td>
                            <td className="a-item-item"> hello@thehariprasad.com</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default About