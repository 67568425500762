import "./skill.css"
import Skills from "./Skills"

const Skill = () => {
  return (
    <>
        <div className="s">
            <div className="s-wrap">
                <h1 className="s-title">My Skills</h1>
            </div>
            <div className="s-wrap">
                <Skills/>
            </div>
        </div>
    </>
  )
}

export default Skill