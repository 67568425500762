import React from 'react'

const Fid = () => {
    return (
        <svg className='w-img fid' width={657} height={381} viewBox="0 0 657 381" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M319.68 375H193.18C189.013 373.834 180.38 369.2 179.18 360L157.18 291.5C152.347 290 143.48 283.9 146.68 271.5C150.68 263 158.18 260.5 174.18 264.5M174.18 264.5L205.18 271.5C205.456 267.633 207.015 262.008 210.68 256.491C215.861 248.693 225.251 241.108 241.18 239H319.68M174.18 264.5C184.013 265.833 203.48 283.1 210.68 313.5H319.68" stroke="black" strokeWidth={4} className="svg-fid-1" />
            <path d="M319 375H445.5C449.667 373.834 458.3 369.2 459.5 360L481.5 291.5C486.333 290 495.2 283.9 492 271.5C488 263 480.5 260.5 464.5 264.5M464.5 264.5L433.5 271.5C433.224 267.633 431.666 262.008 428 256.491C422.819 248.693 413.429 241.108 397.5 239H319M464.5 264.5C454.667 265.833 435.2 283.1 428 313.5H319" stroke="black" strokeWidth={4} className="svg-fid-2" />
            <path d="M0.5 379H69" stroke="black" strokeWidth={4} className="svg-fid-3" />
            <path d="M34.9999 378.294L33.0908 166.384C33.0408 160.826 37.5324 156.294 43.0904 156.294L43.5 156.294" stroke="black" strokeWidth={4} className="svg-fid-4" />
            <path d="M33.4886 142.432L49.5354 129.635L85.9135 158.182L53.0768 184.369L33.4886 142.432Z" fill="#59B256" className="svg-fid-5" />
            <path d="M565.5 292L562.161 375.009C562.073 377.187 563.815 379 565.994 379V379C568.934 379 571.496 376.995 572.201 374.14L592.5 292M565.5 292H562.25C556.865 292 552.5 287.635 552.5 282.25V282.25C552.5 276.865 556.865 272.5 562.25 272.5H604M565.5 292H592.5M592.5 292H604" stroke="black" strokeWidth={4} className="svg-fid-6" />
            <path d="M642 292L645.339 375.009C645.427 377.187 643.685 379 641.506 379V379C638.566 379 636.004 376.995 635.299 374.14L615 292M642 292H645.25C650.635 292 655 287.635 655 282.25V282.25C655 276.865 650.635 272.5 645.25 272.5H603.5M642 292H615M615 292H603.5" stroke="black" strokeWidth={4} className="svg-fid-7" />
            <circle cx="369.5" cy="61.5" r="59.5" stroke="black" strokeWidth={4} className="svg-fid-8" />
            <path d="M362.545 68.0889L405.375 27.0889M362.545 56.7407L389.268 81.2674" stroke="#59B256" strokeWidth={4} className="svg-fid-9" />
        </svg>
    )
}

export default Fid