import React from 'react'

const Lms = () => {
    return (
        <svg className='w-img lms' width={568} height={442} viewBox="0 0 568 442" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={116} y={55} width={424} height={345} rx={19} stroke="black" strokeWidth={4} className="svg-lms-1" />
            <rect x={382} y={151} width={80} height={46} rx={9} stroke="black" strokeWidth={4} className="svg-lms-2" />
            <path d="M434.933 170.93C437.861 172.409 437.861 176.591 434.933 178.07L417.804 186.724C415.143 188.068 412 186.134 412 183.153V165.847C412 162.866 415.143 160.932 417.804 162.276L434.933 170.93Z" fill="#59B256" className="svg-lms-3" />
            <circle cx="379.5" cy="314.5" r="25.5" stroke="black" strokeWidth={4} className="svg-lms-4" />
            <path d="M366.479 314.475L369.912 323.332C370.335 324.422 371.7 324.78 372.603 324.037L395.078 305.549" stroke="#59B256" strokeWidth={6} className="svg-lms-5" />
            <line x1={175} y1={223} x2={256} y2={223} stroke="black" strokeWidth={4} className="svg-lms-6" />
            <line x1={175} y1={234} x2={256} y2={234} stroke="black" strokeWidth={4} className="svg-lms-7" />
            <line x1={175} y1={245} x2={256} y2={245} stroke="black" strokeWidth={4} className="svg-lms-8" />
            <line x1={175} y1={256} x2={256} y2={256} stroke="black" strokeWidth={4} className="svg-lms-9" />
            <line x1={175} y1={267} x2={256} y2={267} stroke="black" strokeWidth={4} className="svg-lms-10" />
            <rect x={179} y={138} width={81} height={52} rx={11} stroke="black" strokeWidth={4} className="svg-lms-11" />
            <rect y={317} width={74} height={25} rx="12.5" fill="#59B256" className="svg-lms-12" />
            <rect x={494} width={74} height={25} rx="12.5" fill="#59B256" className="svg-lms-13" />
            <rect x={442} y={417} width={74} height={25} rx="12.5" fill="#59B256" className="svg-lms-14" />
            <path d="M74.5 328H212.171C213.733 328 215 326.733 215 325.171V259M421.5 143V15.329C421.5 13.7666 422.767 12.5 424.329 12.5H494M380.5 358V426.671C380.5 428.233 381.767 429.5 383.329 429.5H444.5" stroke="#B7B7B7" strokeWidth={4} className="svg-lms-15" />
            <circle cx={216} cy={247} r={11} fill="#59B256" className="svg-lms-16" />
            <circle cx={380} cy={347} r={11} fill="#59B256" className="svg-lms-17" />
            <circle cx={422} cy={146} r={11} fill="#59B256" className="svg-lms-18" />
        </svg>
    )
}

export default Lms