import React from 'react'

const Aill = () => {
    return (
        <svg className="a-img" width={496} height={702} viewBox="0 0 496 702" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M455.967 699.5H35.6467C33.9412 699.5 32.2602 698.896 31.0859 697.659C21.1715 687.217 26.4459 676.37 31.5293 670.933C32.435 669.965 33.7317 669.5 35.0577 669.5C38.0634 669.5 40.5 667.063 40.5 664.058V449.5C40.5 445.634 43.634 442.5 47.5 442.5H437.5C441.366 442.5 444.5 445.634 444.5 449.5V663.5C444.5 667.366 447.634 670.5 451.5 670.5H456.389C458.073 670.5 459.738 671.079 460.881 672.316C468.971 681.063 465.153 691.314 460.922 697.157C459.787 698.724 457.902 699.5 455.967 699.5Z" stroke="black" strokeWidth={4} />
            <path d="M40.5 590L9.37428 545.041C9.12531 544.681 8.9148 544.302 8.72372 543.909C6.39874 539.121 -0.292401 528.898 4.49998 515L82.3728 332.64C83.0951 330.948 84.454 329.609 86.1558 328.912L201.083 281.81C203.747 280.718 206.79 281.385 208.937 283.304C217.671 291.114 234.036 302.041 250 301C259.048 300.844 280.157 297.738 296.27 286.845C298.32 285.459 300.913 285.054 303.216 285.958L412.589 328.858C414.442 329.585 415.897 331.065 416.592 332.93L491 532.5C494 543.667 496.6 568.4 483 578L444.5 604" stroke="black" strokeWidth={4} />
            <path d="M205.5 281V222.5M297.5 284.5L293 222.5" stroke="black" strokeWidth={4} />
            <path d="M172 176.5L148 133L156.07 136L144.5 96.5L153.173 105.051L156.07 63.044L159.174 74.6321L173.038 38.6264V52.6976L192.076 22.8998V31.5908L211.734 9.8633L206.147 22.8998L241.325 2L231.185 15.4504L268.226 2L254.155 15.4504L295.747 8.00094L282.918 19.589L324.924 22.8998L299.472 28.073L344.376 38.6264H312.715L354.308 56.2154L324.924 52.6976L360.102 76.4944L334.443 68.2173L370.862 99.2566L340.444 84.7716L370.862 115.19L344.376 101.533L376.45 138.366L348.307 123.26L370.862 160.301L348.307 145.402L330.5 167" stroke="black" strokeWidth={4} />
            <path d="M307.549 108.756C275.186 91.3684 232.188 85.1274 213.336 84.0683C212.444 84.0183 211.556 84.1463 210.725 84.4722C177.996 97.3063 174.341 154.663 179.756 183.26C179.914 184.094 180.233 184.868 180.687 185.585L209.939 231.747C211.222 233.772 213.453 235 215.851 235H281.451C283.682 235 285.779 233.937 287.098 232.137L321.116 185.707C321.699 184.911 322.112 183.999 322.301 183.031C328.927 149.22 314.539 114.9 308.382 109.317C308.128 109.087 307.85 108.919 307.549 108.756Z" stroke="black" strokeWidth={4} />
            <path d="M261.225 600.451C253.099 604.813 246.099 607.464 240.199 608.5C234.309 609.533 229.638 608.939 226.008 606.97C218.781 603.048 214.581 592.973 214.48 575.236C214.38 557.486 218.471 547.481 225.621 543.642C229.217 541.712 233.877 541.158 239.784 542.247C245.701 543.337 252.735 546.055 260.914 550.493C269.096 554.934 275.106 559.296 279.054 563.55C282.995 567.796 284.767 571.803 284.789 575.599C284.81 579.391 283.087 583.371 279.203 587.571C275.31 591.782 269.355 596.086 261.225 600.451Z" stroke="#59b256" strokeWidth={4} />
            <path d="M258.692 593.517C225.767 611.192 209.204 602.203 209.002 566.551C208.8 530.899 225.262 522.062 258.389 540.04C291.516 558.017 291.617 575.843 258.692 593.517Z" fill="#59b256" />
        </svg>
    )
}

export default Aill