import React from 'react'
import About from './About/About'
import Intro from './Intro/Intro'
import Work from './Work/Work'
import Skill from './Skill/Skill'
import Contact from './Contacts/Contact'
import Footer from './Footer/Footer'


const Home = () => {
    return (
        <div>
            <Intro />
            <About />
            <div className="w-app-head">
                <h1 className="w-app-title">My works</h1>
            </div>
            <Work />
            <Skill />
            <Contact />
            <Footer/>
        </div>
    )
}

export default Home