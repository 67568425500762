
const Green = () => {
    return (
        <svg className='w-img green' width={570} height={494} viewBox="0 0 570 494" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M119.5 336.5C184.7 373.7 204.5 458 213.5 492.5C194.5 390 186.5 303.5 121.5 279C175.5 278.2 197 345 201 378.5C207 330.1 205.5 322.5 202 299C193.5 257.5 165 246.183 144.5 240.5C94 226.5 83 233 3 210.5C7.8 260.5 36 291 49.5 300C83 320.5 91.5 320.525 119.5 336.5Z" stroke="#59B256" strokeWidth={4} className="svg-elem-1" />
            <path d="M373.295 212.501C264.886 274.354 231.964 414.52 217 471.884C248.591 301.456 261.893 157.632 369.969 116.895C280.183 115.565 244.435 226.634 237.784 282.335C227.808 201.86 230.302 189.223 236.121 150.15C250.254 81.1473 297.641 62.3307 331.727 52.8812C415.694 29.6033 433.983 40.4109 567 3C559.019 86.1354 512.131 136.848 489.684 151.812C433.983 185.898 419.85 185.939 373.295 212.501Z" stroke="#59B256" strokeWidth={4} className="svg-elem-2" />
        </svg>
    )
}

export default Green