import React from 'react'

const Bot = () => {
    return (
        <svg className='w-img bot' width={432} height={234} viewBox="0 0 432 234" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M332 2H134C78.7715 2 34 46.7715 34 102V151L4 229.5L78 198H332C386.124 198 430 154.124 430 100C430 45.8761 386.124 2 332 2Z" stroke="black" strokeWidth={4} className="svg-bot-1" />
            <rect x={77} y={44} width={306} height={116} rx={58} stroke="black" strokeWidth={4} className="svg-bot-2" />
            <circle cx={142} cy={102} r={26} fill="#59B256" className="svg-bot-3" />
            <circle cx={326} cy={102} r={26} fill="#59B256" className="svg-bot-4" />
            <path d="M199 131C208.812 140 237.148 152.6 272 131" stroke="black" strokeWidth={4} className="svg-bot-5" />
        </svg>
    )
}

export default Bot