import './App.css'
import Home from './Components/Home'
import Pg404 from './Components/Pg404'

import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

const App = () => {
	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="*" element={<Pg404 />} />
				</Routes>
			</Router>
		</div>
	)
}

export default App