import React from 'react'
import './intro.css'
import Hari from './Hari'

const Intro = () => {
    return (
        <div className="i">
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h2 className="i-intro">Hello, I am</h2>
                    <h2 className="i-name">Hari Prasad</h2>
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item">Web Developer</div>
                            <div className="i-title-item">Data Analyst</div>
                            <div className="i-title-item">UI designer</div>
                            {/* <div className="i-title-item">Digital Marketer</div> */}
                            {/* <div className="i-title-item">Freelancer</div> */}
                        </div>
                    </div>
                    <div>
                        <p className="i-desc i-above">
                            Hi, Welcome to my Website.
                        </p>
                        <p className="i-desc i-below">
                            I, Hari Prasad, a Fullstack Developer showcase my online presence through this website.
                        </p>
                    </div>
                </div>
            </div>
            <div className="i-right">
                <div className="i-bg"></div>
                <Hari/>
            </div>
        </div>
    )
}

export default Intro