import "./contact.css"
import { useState } from "react"

const Contact = () => {
	const [name, setName] = useState("");
	const [mail, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [resp, setResp] = useState("");
	// let resMsg = "Hi"
	let stJson = JSON.stringify({
		name: name,
		mail: mail,
		sub: subject,
		msg: message,
	})

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			let res = await fetch("/auth/contact", {
				method: "POST",
				body: stJson,
				headers: {
					'Content-Type': 'application/json',
				}
			})
			if (res.status === 200) {
				setName("")
				setEmail("")
				setSubject("")
				setMessage("")
				setResp("Thank you for contacting me!")
			}
			else
				setResp("Sorry some error occured! Try again later.")
			console.log(res)
		}
		catch (err) {
			console.log(err)
		}
	}

	return (
		<div className="c" >
			<div className="c-left">
				<div className="c-contain">
					<h1 className="c-title">Contact me</h1>
				</div>
				<div className="c-card">
					<svg className='c-img' width="490" height="354" viewBox="0 0 490 354" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M487 3L6 126.5L154.5 195.275M487 3L392.5 305.5L285.5 255.945M487 3L154.5 195.275M487 3L210.5 221.21M210.5 221.21L171.5 349.5M210.5 221.21L285.5 255.945M171.5 349.5L154.5 195.275M171.5 349.5L285.5 255.945" stroke="black" stroke-width="4" />
					</svg>
				</div>
			</div>
			<div className="c-right">
				<form onSubmit={handleSubmit}>
					<input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name" />
					<input type="text" value={mail} onChange={e => setEmail(e.target.value)} placeholder="Email" />
					<input type="text" value={subject} onChange={e => setSubject(e.target.value)} placeholder="Subject" />
					<textarea placeholder="Message" value={message} onChange={e => setMessage(e.target.value)} rows="10"></textarea>
					<div className="c-res">{resp ? <p>{resp}</p> : null}</div>
					<button type="submit">Send</button>
				</form>
			</div>
		</div>
	)
}

export default Contact