import React from 'react'

const Link = () => {
    return (
        <svg className='w-img link' width={451} height={479} viewBox="0 0 451 479" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M291.296 284.635L351.546 388.991C367.425 416.493 358.002 451.66 330.5 467.538C302.998 483.416 267.832 473.993 251.953 446.491L160.454 288.009C144.575 260.507 153.998 225.341 181.5 209.462C209.002 193.584 244.168 203.007 260.046 230.509L272.046 251.293" stroke="black" strokeWidth={6} className="svg-link-1" />
            <path d="M169 193.653L108.75 89.2966C92.8718 61.7948 102.295 26.6284 129.796 10.7502C157.298 -5.12798 192.465 4.29483 208.343 31.7966L299.843 190.279C315.721 217.781 306.298 252.947 278.796 268.826C251.295 284.704 216.128 275.281 200.25 247.779L188.25 226.995" stroke="black" strokeWidth={6} className="svg-link-2" />
            <path d="M127.657 241.657C130.781 238.533 130.781 233.467 127.657 230.343L76.7452 179.431C73.621 176.307 68.5557 176.307 65.4315 179.431C62.3073 182.556 62.3073 187.621 65.4315 190.745L110.686 236L65.4315 281.255C62.3073 284.379 62.3073 289.444 65.4315 292.569C68.5557 295.693 73.621 295.693 76.7452 292.569L127.657 241.657ZM0 244H122V228H0V244Z" fill="#59B256" className="svg-link-3" />
            <path d="M323.343 241.657C320.219 238.533 320.219 233.467 323.343 230.343L374.255 179.431C377.379 176.307 382.444 176.307 385.569 179.431C388.693 182.556 388.693 187.621 385.569 190.745L340.314 236L385.569 281.255C388.693 284.379 388.693 289.444 385.569 292.569C382.444 295.693 377.379 295.693 374.255 292.569L323.343 241.657ZM451 244H329V228H451V244Z" fill="#59B256" className="svg-link-4" />
        </svg>
    )
}

export default Link